import React, { useEffect, useState } from 'react'

// GraphQL
import { getGraphComics } from '../../../graphQL'

// Model
import { ComicModel } from '../../../models'

// Utils
import { shuffleArray } from '../../../utils/utils'

// Component
import ComicCard from '../../atoms/ComicCard'

// Styles
import {
  similarGrid,
  similarTitle,
  similarContainer,
} from './style.module.scss'

interface Props {
  tags: string
}
interface Matches extends ComicModel {
  matches: number
}

const SimilarComics: React.FC<Props> = ({ tags }) => {
  const comics = getGraphComics()
  const [similar, setSimilar] = useState<Matches[]>([])

  const matchTags = tags.split(',').map((tag) => tag.trim())

  const similarComics = (): Matches[] => {
    const similar = comics.map((comic) => {
      let matches = 0
      matchTags.forEach((tag) => {
        if (comic.tags.other?.includes(tag) && comic.tags.all !== tags)
          matches += 1
      })
      if (comic.id === '3813110') matches += 1
      return { ...comic, matches }
    })
    const result = shuffleArray(similar).sort((a: Matches, b: Matches) => {
      if (a.matches > b.matches) return -1
      return 1
    })

    return result.splice(0, 5)
  }

  useEffect(() => {
    setSimilar(similarComics())
  }, [comics])

  return (
    <div className={similarContainer}>
      <h2 className={similarTitle}>Cómics similares</h2>
      <div className={similarGrid}>
        {similar.map((s) => (
          <ComicCard comic={s} key={`similar_${s.id}`} />
        ))}
      </div>
    </div>
  )
}

export default SimilarComics
