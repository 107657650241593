import React, { BaseSyntheticEvent, useContext, useRef } from 'react'

// Icons
import NoCover from '../../../assets/icons/NoCover.png'
import VariantContext from '../../../context/Variant/Variant.context'
import useWindowSize from '../../../hooks/windowSize'

// Styles
import { coverImage, coverWrapper, variantImage } from './style.module.scss'

interface Props {
  name: string
  cover: string | null
}
const CoverImage: React.FC<Props> = ({ name, cover }) => {
  const image = useRef<HTMLImageElement>(null)
  const { variant } = useContext(VariantContext)

  const {width} = useWindowSize()

  return (
    <div className={coverWrapper}>
      {variant && (
        <img
          style={{
            width: `${width > 0 && image.current ? image.current.offsetWidth : 0}px`,
            height: `${width > 0 && image.current ? image.current.offsetHeight : 0}px`,
          }}
          className={variantImage}
          alt={`Portada ${name}`}
          src={`https://comiccolombiano.com/.img/variants/${variant}`}
        />
      )}
      <img
        ref={image}
        style={{ opacity: variant ? 0 : 1 }}
        className={coverImage}
        alt={`Portada ${name}`}
        src={`https://comiccolombiano.com/.img/covers/${cover}`}
        onError={(e: BaseSyntheticEvent) => {
          e.target.src = NoCover
        }}
      />
    </div>
  )
}

export default CoverImage
